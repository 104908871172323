const env = process.env;
export const ENV = env.REACT_APP_ENVIRONMENT_NAME;
export const PASSCODE = env.REACT_APP_ADMIN_PASSCODE;
export const AUTH0_AUDIENCE = env.REACT_APP_AUTH0_AUDIENCE;
export const AUTH0_CLIENT_ID = env.REACT_APP_AUTH0_CLIENT_ID;
export const AUTH0_DOMAIN = env.REACT_APP_AUTH0_DOMAIN;
const fulUrlPart = ENV === 'prd' || ENV === 'prod' ? '' : `-${ENV}`;
export const BACKEND_URL = `https://api.bda.banking${fulUrlPart}.safeguard-pay.com`;

export const userflowToken: string = env.REACT_APP_USERFLOW_TOKEN || '';

export const allowedFilesToUpload = [
  'json',
  'txt',
  'xml',
  'csv',
  'xls',
  'dat',
  'fil',
];

export const SECONDS_IN_MIN = 60;
console.log('Domain & Client_Id:', AUTH0_DOMAIN, AUTH0_CLIENT_ID);
